import { ComboBox } from '@interstate/components/ComboBox'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { auctionOptionsUtil } from '../../utils/auction.util'
import { PatInputStyle } from './PatInputStyle'
import { useAuth } from '../../auth/AuthProvider'

export function LocationComboBox({
    value,
    onChange,
    multiple = true,
    disabled = false,
    all = false,
    filterOut = [],
    auctionEditFilter = false,
    dataTestId
}) {
    const staticData = useStaticDataContext()
    const authService = useAuth()
    const auctionOptions =
        auctionEditFilter === true
            ? auctionOptionsUtil(staticData, all, authService.principal)
            : auctionOptionsUtil(staticData, all, null)

    let displayValue = value
    if (value && typeof value[0] === 'string') {
        displayValue = auctionOptions?.filter((ao) => value.includes(ao.value))
    }

    let filteredOptions = auctionOptions?.filter(
        (ao) => !filterOut.includes(ao.value)
    )

    return (
        <ComboBox
            label={multiple ? 'Location(s)' : 'Location'}
            data-testid={dataTestId}
            options={filteredOptions}
            onChange={onChange}
            value={displayValue}
            multiple={multiple}
            disabled={disabled}
            sx={PatInputStyle}
        />
    )
}
