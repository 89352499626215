import { React, useState } from 'react'
import { SearchFees } from '../components/search/SearchFees'
import { useSearchParams } from 'react-router-dom'
import { FeesGrid } from '../components/search/FeesGrid'
import { useSearchFee } from '../service/feeScheduleService'
import { Table } from '@interstate/components/Table'
import { Tooltip } from '../components/ToolTip'
import { RateCardOnTooltip } from '../components/ratecard/RateCardOnTooltip'
import {
    describeFeeStructureTierFormat,
    renderDateFromIsoString
} from '../utils/Formatters'
import { InfoIcon } from '../components/Icons'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'

export function FeeResultsView(props) {
    const [queryParams] = useSearchParams()
    const searchText = queryParams.get('q')

    let feeScheduleTypes = queryParams.get('feeScheduleTypes')
    let auctionCodes = queryParams.get('auctionCodes')
    let saleTypes = queryParams.get('saleTypes')
    let feeTypes = queryParams.get('feeTypes')
    let accounts = queryParams.get('accounts')
    let groupCodes = queryParams.get('groupCodes')
    let altGroupCodes = queryParams.get('altGroupCodes')
    let startDate = queryParams.get('startDate')
    let endDate = queryParams.get('endDate')
    let includeSaleTypeParent = queryParams.get('includeSaleTypeParent')
    let includeDeletedExpired = queryParams.get('includeDeletedExpired')

    const {
        value: searchFeeResults,
        loading: searchFeeLoading,
        error: searchFeeError
    } = useSearchFee({
        feeScheduleTypes,
        auctionCodes,
        saleTypes,
        feeTypes,
        accounts,
        groupCodes,
        altGroupCodes,
        startDate,
        endDate,
        includeSaleTypeParent,
        includeDeletedExpired
    })

    const [consolidate, setConsolidate] = useState(true)

    const handleConsolidateChange = (evt) => {
        setConsolidate(evt.target.checked)
    }

    function displayFeeStructure(saleTypeFee) {
        return (
            <Tooltip
                content={
                    <RateCardOnTooltip
                        feeStructure={saleTypeFee.feeStructure}
                    />
                }
                direction="bottom">
                <span>
                    {describeFeeStructureTierFormat(saleTypeFee.feeStructure)}
                    &nbsp;
                    <InfoIcon />
                </span>{' '}
                &nbsp;
            </Tooltip>
        )
    }

    return (
        <Container>
            <PageHeader titleValue="Fee Schedules" />
            {/*<div>*/}
            {/*    <h1>Search Results</h1>*/}
            {/*</div>*/}
            <SearchFees />
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>
                {!searchFeeLoading && !searchFeeError && (
                    <Table
                        columns={[
                            {
                                className: 'column-auctionCode',
                                dataIndex: 'buCode',
                                render: (item) => <div>{item}</div>,
                                title: 'Auction code'
                            },
                            {
                                className: 'column-customer',
                                dataIndex: 'customer',
                                title: 'Customer'
                            },
                            {
                                className: 'column-feeType',
                                dataIndex: 'feeType',
                                title: 'Fee type'
                            },
                            {
                                className: 'column-saleType',
                                dataIndex: 'saleType',
                                title: 'Sale type'
                            },
                            {
                                className: 'column-startDate',
                                dataIndex: 'startDate',
                                render: (item) => (
                                    <div>
                                        {renderDateFromIsoString(
                                            item,
                                            'MMM DD YYYY'
                                        )}
                                    </div>
                                ),
                                title: 'Start date'
                            },
                            {
                                className: 'column-endDate',
                                dataIndex: 'endDate',
                                render: (item) => (
                                    <div>
                                        {renderDateFromIsoString(
                                            item,
                                            'MMM DD YYYY'
                                        )}
                                    </div>
                                ),
                                title: 'End date'
                            },
                            {
                                className: 'column-feeStructure',
                                render: (item) => (
                                    <div>{displayFeeStructure(item)}</div>
                                ),
                                title: 'Fee structure'
                            }
                        ]}
                        data={searchFeeResults}
                        tableLayout={'auto'}
                        enablePagination={true}
                        id="overlappingFeesTable"
                        onChangePage={function Ua() {}}
                        onChangePageSize={function Ua() {}}
                        onChangeSelectedRows={function Ua() {}}
                        onChangeSorter={function Ua() {}}
                        onExpandClick={function Ua() {}}
                        onRowCallback={function Ua() {}}
                        onRowSelect={function Ua() {}}
                    />
                )}

                {/*<SearchFees text={searchText} />*/}
                {/*<label>*/}
                {/*    Consolidate Rows?{' '}*/}
                {/*    <input*/}
                {/*        type="checkbox"*/}
                {/*        checked={consolidate}*/}
                {/*        onChange={handleConsolidateChange}*/}
                {/*    />*/}
                {/*</label>*/}
                {/*{!errorSearchFee && !loadingSearchFee && searchFeeResults && (*/}
                {/*    <FeesGrid*/}
                {/*        fees={searchFeeResults}*/}
                {/*        consolidate={consolidate}*/}
                {/*        csvName={`current-fees-${searchText}-${new Date().toLocaleDateString().replace('/', '-')}`}></FeesGrid>*/}
                {/*)}*/}
                {/*{!loadingSearchFee && errorSearchFee && (*/}
                {/*    //for now, just show test data when we haven't searched anything*/}
                {/*    <div className="error">*/}
                {/*        Error searching fees: {errorSearchFee.message}*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </Container>
    )
}
