import { React, useEffect, useState, useCallback } from 'react'
import { PrimaryActionButton } from '../InputControls'
import {
    createSearchParams,
    useNavigate,
    useSearchParams
} from 'react-router-dom'
import { Grid } from '@interstate/components/Grid'
import { FeeScheduleTypeSelector } from '../FeeScheduleTypeSelector'
import { LocationComboBox } from '../common/LocationComboBox'
import { FeeTypesComboBox } from '../common/FeeTypesComboBox'
import { SaleTypesComboBox } from '../common/SaleTypesComboBox'
import { PatDatePicker } from '../common/PatDatePicker'
import { TextArea } from '@interstate/components/TextArea'
import { CheckBox } from '@interstate/components/CheckBox'

export function SearchFees({ text, ...props }) {
    const navigate = useNavigate()
    const [queryParams] = useSearchParams()

    // const [searchText, setSearchText] = useState(text || '');
    const [errors, setErrors] = useState()
    const [feeScheduleType, setFeeScheduleType] = useState()
    const [selectedLocations, setSelectedLocations] = useState()
    const [feeType, setFeeType] = useState()
    const [saleType, setSaleType] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [accounts, setAccounts] = useState([])
    const [groupCodes, setGroupCodes] = useState([])
    const [altGroupCodes, setAltGroupCodes] = useState([])
    const [includeDeletedExpired, setIncludeDeletedExpired] = useState(false)
    const [includeSaleTypeParent, setIncludeSaleTypeParent] = useState(false)

    useEffect(() => {
        if (queryParams) {
            ifValuePresent(queryParams.get('accounts'), () =>
                setAccounts(queryParams.get('accounts')?.split(','))
            )
            ifValuePresent(queryParams.get('groupCodes'), () =>
                setGroupCodes(queryParams.get('groupCodes')?.split(','))
            )
            ifValuePresent(queryParams.get('altGroupCodes'), () =>
                setAltGroupCodes(queryParams.get('altGroupCodes')?.split(','))
            )
            // setFeeScheduleType(queryParams.get('feeScheduleType'))
            // setSelectedLocations(queryParams.get('selectedLocations'))
            ifValuePresent(queryParams.get('feeTypes'), () =>
                setFeeType(queryParams.get('feeTypes'))
            )
            ifValuePresent(queryParams.get('saleTypes'), () =>
                setSaleType(queryParams.get('saleTypes'))
            )
            ifValuePresent(queryParams.get('startDate'), () =>
                setStartDate(queryParams.get('startDate'))
            )
            ifValuePresent(queryParams.get('endDate'), () =>
                setEndDate(queryParams.get('endDate'))
            )
            ifValuePresent(queryParams.get('includeDeletedExpired'), () => {
                setIncludeDeletedExpired(
                    queryParams.get('includeDeletedExpired')
                )
            })
            ifValuePresent(queryParams.get('includeSaleTypeParent'), () => {
                setIncludeSaleTypeParent(
                    queryParams.get('includeSaleTypeParent')
                )
            })
        }
    }, [queryParams])

    function ifValuePresent(value, callback) {
        if (value && value.length > 0) {
            callback()
        }
    }

    function performSearch() {
        // const errors = validateSearchFields({searchText})

        if (!errors) {
            const newQueryParams = {
                includeSaleTypeParent,
                includeDeletedExpired
            }

            ifValuePresent(
                feeScheduleType,
                () => (newQueryParams.feeScheduleTypes = feeScheduleType)
            )
            ifValuePresent(
                selectedLocations,
                () => (newQueryParams.auctionCodes = selectedLocations)
            )
            ifValuePresent(
                accounts,
                () => (newQueryParams.accounts = accounts?.join(','))
            )
            ifValuePresent(
                groupCodes,
                () => (newQueryParams.groupCodes = groupCodes?.join(','))
            )
            ifValuePresent(
                altGroupCodes,
                () => (newQueryParams.altGroupCodes = altGroupCodes?.join())
            )
            ifValuePresent(feeType, () => (newQueryParams.feeTypes = feeType))
            ifValuePresent(
                saleType,
                () => (newQueryParams.saleTypes = saleType)
            )
            ifValuePresent(
                startDate,
                () => (newQueryParams.startDate = startDate)
            )
            ifValuePresent(endDate, () => (newQueryParams.endDate = endDate))

            navigate({
                pathname: '/boc/searchFeeResults',
                search: `?${createSearchParams(newQueryParams)}`
            })

            // navigate(`/boc/feeResults`)
            // setErrors('')
        } else {
            // setErrors(errors)
        }
    }

    function onInputChange(event) {
        switch (event.target.name) {
            case 'feeScheduleType':
                setFeeScheduleType(event.target.value)
                break
            case 'saleType':
                setSaleType(event.target.value)
                break
            case 'feeType':
                setFeeType(event.target.value)
                break
            case 'startDate':
                setStartDate(event.target.value?.additionalFormats?.iso)
                break
            case 'endDate':
                setEndDate(event.target.value?.additionalFormats?.iso)
                break
            case 'accounts':
                setAccounts(event.target.value.replace(' ', '').split(','))
                break
            case 'groupCodes':
                setGroupCodes(event.target.value.replace(' ', '').split(','))
                break
            case 'altGroupCodes':
                setAltGroupCodes(event.target.value.replace(' ', '').split(','))
                break
            case 'includeDeletedExpired':
                if (event.target.value.checked) {
                    setIncludeDeletedExpired(true)
                } else {
                    setIncludeDeletedExpired(false)
                }
                break
            case 'includeSaleTypeParent':
                if (event.target.value.checked) {
                    setIncludeSaleTypeParent(true)
                } else {
                    setIncludeSaleTypeParent(false)
                }
                break
            default:
                break
        }
    }

    // const handleCheckBox0 = useCallback(
    //     (e: InterstateOnChangeEvent<CheckBoxEventValue>) => {
    //         console.log(e.target);
    //         setCheckBox0(e.target?.value?.checked);
    //     },
    //     []
    // );
    // const handleCheckBox1 = useCallback(
    //     (e: InterstateOnChangeEvent<CheckBoxEventValue>) => {
    //         console.log(e.target);
    //         setCheckBox0(e.target?.value?.checked);
    //     },
    //     []
    // );

    function onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault()
            performSearch()
        }
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <div>
                        <FeeScheduleTypeSelector
                            value={feeScheduleType}
                            name="feeScheduleType"
                            onChange={onInputChange}
                            moduleID="FeeScheduleType"
                        />
                        {/*<ComboBox*/}
                        {/*    label={"Fee schedule type"}*/}
                        {/*    data-testid=""*/}
                        {/*    options={filteredOptions}*/}
                        {/*    onChange={onInputChange}*/}
                        {/*    multiple={multiple}*/}
                        {/*    disabled={false}*/}
                        {/*    sx={PatInputStyle}*/}
                        {/*/>*/}
                    </div>
                </Grid>
                <Grid xs={12} md={4}>
                    <LocationComboBox
                        value={selectedLocations}
                        multiple={true}
                        onChange={onInputChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <FeeTypesComboBox
                        name={'feeType'}
                        value={feeType}
                        onChange={onInputChange}
                        scheduleFeeType={feeScheduleType}
                        dataTestId={'FeeType_stflI'}
                    />
                </Grid>

                <Grid xs={12} md={4}>
                    <SaleTypesComboBox
                        name="saleType"
                        value={saleType}
                        onChange={onInputChange}
                        data-testid={'SaleType_stflI'}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    data-testid="container_DatePicker_StartDate">
                    <PatDatePicker
                        name="startDate"
                        label="Start Date"
                        data-testid="datePicker_StartDate"
                        onChange={onInputChange}
                        value={new Date(startDate)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    data-testid="container_DatePicker_EndDate">
                    <PatDatePicker
                        name="endDate"
                        label="End Date"
                        data-testid="datePicker_EndDate"
                        onChange={onInputChange}
                        value={new Date(endDate)}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextArea
                        name="accounts"
                        label="Accounts"
                        data-testid="textarea_Accounts"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={(accounts || []).join(', ')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextArea
                        name="groupCodes"
                        label="Group Codes"
                        data-testid="textarea_GroupCodes"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={(groupCodes || []).join(', ')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextArea
                        name="altGroupCodes"
                        label="Alternate Group Codes"
                        data-testid="textarea_AlternateGroupCodes"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={(altGroupCodes || []).join(', ')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div>
                        <CheckBox
                            label="Include Sale Type Parent"
                            className=""
                            data-testid=""
                            name="includeSaleTypeParent"
                            onChange={onInputChange}
                            value={'includeSaleTypeParentTrue'}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div>
                        <CheckBox
                            label="Include Deleted/Expired"
                            className=""
                            data-testid=""
                            name="includeDeletedExpired"
                            onChange={onInputChange}
                            value={'includeDeletedExpiredTrue'}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <PrimaryActionButton
                    name="btnSearch"
                    label="Search"
                    onClick={performSearch}></PrimaryActionButton>
            </Grid>
        </div>
    )
}
